<template>
    <div class="counter-place low">
        <div class="first-line">
            <p class="middle-high">{{nameShow}}</p>
            <button @click="openWindowParam()">
                <img src="@/assets/imgs/pencil.svg" alt="">
            </button>
        </div>
        <div class="param-place">
            <div class="param-column">
                <div class="param-el" popup-text="Напржение, В">
                    <div class="icon-param">
                        <img src="@/assets/imgs/counter/Voltage.svg" alt="">
                    </div>
                    <p class="the-biggest">{{voltageShow}}</p>
                    <p class="small-text name-param">Напржение, В</p>
                </div>
                <div class="param-el" popup-text="Частота, Гц">
                    <div class="icon-param">
                        <img src="@/assets/imgs/counter/Frequency.svg" alt="">
                    </div>
                    <p class="the-biggest">{{frequencyShow}}</p>
                    <p class="small-text name-param">Частота, Гц</p>
                </div>
                <div class="param-el" popup-text="cos φ">
                    <div class="icon-param">
                        <img src="@/assets/imgs/counter/CosFi.svg" alt="">
                    </div>
                    <p class="the-biggest">{{cosFiShow}}</p>
                    <p class="small-text name-param">cos φ</p>
                </div>
            </div>
            <div class="param-column">
                <div class="param-el" popup-text="Ток, А">
                    <div class="icon-param">
                        <img src="@/assets/imgs/counter/Current.svg" alt="">
                    </div>
                    <p class="the-biggest">{{currentShow}}</p>
                    <p class="small-text name-param">Ток, А</p>
                </div>
                <div class="param-el" popup-text="Электроэнергия, кВт/ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/counter/Energy.svg" alt="">
                    </div>
                    <p class="the-biggest">{{energyShow}}</p>
                    <p class="small-text name-param">Электроэнергия, кВт/ч</p>
                </div>
                <div class="param-el" popup-text="Электроэнергия(день), кВт/ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/counter/Energy.svg" alt="">
                    </div>
                    <p class="the-biggest">{{energyDayShow}}</p><!-- issue -->
                    <p class="small-text name-param">Электроэнергия(день), кВт/ч</p>
                </div>
                <button class="white-status" @click="openGraphWindow()">
                    <img src="@/assets/imgs/icon-chart.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Counter',
    props: ['name', 'id', 'address', 'voltage', 'frequency', 'cosFi', 'current', 'energy', 'energyDay'],
    emits: ['open-graf', 'open-param'],
    methods: {
        openGraphWindow() {
            this.$emit('open-graf', this.id, this.name, 'counter')
        },
        openWindowParam() {
            this.$emit('open-param', this.id, this.name, 'counter')
        },
    },
    computed: {
        nameShow() {
            if (this.name) {
                return this.name
            } else {
                return this.address
            }
        },
        voltageShow() {
            if (this.voltage) {
                return this.voltage.toFixed(2)
            } else {
                return '-'
            }
        },
        frequencyShow() {
            if (this.frequency) {
                return this.frequency.toFixed(2)
            } else {
                return '-'
            }
        },
        cosFiShow() {
            if (this.cosFi) {
                return this.cosFi.toFixed(2)
            } else {
                return '-'
            }
        },
        currentShow() {
            if (this.current) {
                return this.current.toFixed(2)
            } else {
                return '-'
            }
        },
        energyShow() {
            if (this.energy) {
                return this.energy.toFixed(2)
            } else {
                return '-'
            }
        },
        energyDayShow() {
            if (this.energyDay) {
                return this.energyDay.toFixed(2)
            } else {
                return '-'
            }
        }
    }
}
</script>
<style noscoped lang="scss">
    .counter-place {
        // max-width: 573px;
        // min-width: 358px;
        height: 30.6vh;
        min-height: 97px;
        border-radius: 4px;
        background: var(--gray-color);
    }
    .counter-place.low {
        // max-width: 573px;
        // min-width: 358px;
        height: 30.6vh;
    }
    
    .counter-place.high {
        // max-width: 573px;
        // min-width: 358px;
        height: 36.6vh;
    }
    .first-line {
        display: flex;
        justify-content: space-between;
        padding: 3px 5px;

        button {
            cursor: pointer;
            background: none;
            padding: 2px 3px;
            border-radius: 3px;
            transition: all .2s ease;
            
            &:hover {
                background: #f8f6f6;
            }
            &:active {
                background: var(--background-white, #FCFCFC);
            }
        }
    }
    .param-place {
        display: flex;
        justify-content: space-between;
        padding: 0 19px;
        .param-column {
            width: 47%;
        }

        .color-param-to {
            background: rgba(149, 149, 253, 0.39);
        }
        .param-el {
            // width: 9vw;
            width: 100%;
            min-width: 117px;
            height: 5.5vh;
            min-height: 49px;
            border-radius: 5px;
            border: 2px solid var(--background-white, #FCFCFC);
            margin-bottom: 10px;
            position: relative;
            padding: 1px 11px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .icon-param {
                position: absolute;
                top: 4px;
                right: 4px;
                img {
                    height: 4vh;
                }
            }
            &:after {
                position: absolute;
                z-index: 1;
                white-space: nowrap;
                // height: 100%;
                top: 102%;
                background: var(--background-white);
                font-size: 14px;
                padding: 2px 5px;
                box-shadow: 0px 20px 40px 9px rgba(0, 0, 0, 0.26);
                display: flex;
                border-radius: 3px;
                flex-direction: column;
                justify-items: center;
                align-self: center;
                content: attr(popup-text);
                color: black;
                opacity: 0;
                transition: opacity .2s ease;
            }

            &:hover::after {
                // &:after{
                opacity: 1;
            }
        }
        button {
            width: 100%;
            min-width: 117px;
            height: 5.5vh;
            min-height: 49px;
            border-radius: 5px;
            border: 2px solid var(--background-white, #FCFCFC);
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s ease;

            &:hover {
                background: #f8f6f6;
            }
            &:active {
                background: var(--gray-color, #EDEDED);
            }
        }
        .white-status {
            background: var(--background-white, #FCFCFCFF);
        }
        .green-status {
            background: var(--green-color, #B3DDBE);
        }
        .red-status {
            background: var(--red-color, #F97676);
        }
    }
    @media screen and (max-width: 1700px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-column {
            width: 33.3%;
        }
        
    }
    @media screen and (max-width: 1210px) {
        .name-param {
            display: contents;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
    }
    @media screen and (max-width: 1150px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-column {
            width: 33.3%;
        }
    }
    @media screen and (max-width: 810px) {
        .name-param {
            display: contents;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
    }
    @media screen and (max-width: 610px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-place {
            padding: 0 1px;
        }
        .param-column {
            width: 33.3%;
        }
    }
    @media screen and (max-height: 887px) {
        .param-place {
            .param-el {
                margin-bottom: 2px;
            }
        }
        .first-line {
            p {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            img {
                height: 13px;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
</style>
