<template>
    <div class="main-dask-company-page">
        <div class="companies-place">
            <div class="control-place">
                <input type="text" class="filter-company" placeholder="Поиск">
                <button 
                    class="add-company"
                    :class="{
                        'cancel-add-company': flagCreateCompanyForm
                    }"
                    @click="clickCreateCompany"
                >
                    <p class="sign-button">+</p>
                    <p class="text-button" v-if="!flagCreateCompanyForm">&nbsp;Добавить</p>
                    <p class="text-button" v-if="flagCreateCompanyForm">&nbsp;Отменить</p>
                </button>
            </div>
            <div class="company-list-place">
                <CompanyLine v-for="item in companyList" :key="item.id"
                    :name="item.showing_name"
                    :usersNumber="item.count_users"
                    @closeChoose="chooseCompany({id:0})"
                    :flagChoose="choosedCompanyId==item.id"
                    @click="chooseCompany({
                        id: item.id,
                        name: item.showing_name,
                        shortName: item.name
                    })"
                />
            </div>
        </div>
        <div class="middle-column">
            <Transition name="fade" mode="out-in">
                <component
                    :idCompany="paramUserForm.id"
                    :shortNameCompanyForm="paramUserForm.shortName"
                    :nameUserForm="paramUserForm.name"
                    :flagChangeForm="flagChangePassword"
                    :paramUserChangePassword="paramUserChangePassword"
                    @createCompany="addCompany"
                    @changeCompany="changeCompany"
                    @addNewUser="addNewUser"
                    @closeFormChangePassword="flagChangePassword = !flagChangePassword"
                    @changePassword="requestChangePasswordUser"
                    :is="middleColumn"
                />
            </Transition>
        </div>
        <div class="right-column">
            <Transition name="fade" mode="out-in">
                <component
                    :userList="userList"
                    :is="rightColumn"
                    @deleteUser="deleteUser"
                    @changeUser="changePasswordUser"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import { shallowRef } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import CompanyLine from '@/components/Simple_elements/CompanyLine.vue'
import CreateCompanyForm from '@/components/Simple_elements/CreateCompanyForm.vue'
import AddNewUsersForm from './AddNewUsersForm.vue'
import UsersListByCompany from './UsersListByCompany.vue'

export default {
    name: 'Company',
    computed: {
        ...mapGetters({
            companyList: 'getCompanyListByCompanyPage',
            userList: 'getUserListByCompanyPage'
        })
    },
    data() {
        return {
            flagCreateCompanyForm: false,
            middleColumn: shallowRef(),
            rightColumn: shallowRef(),
            paramUserForm: {
            },
            choosedCompanyId: 0,
            flagChangePassword: false,
            paramUserChangePassword: {}
        }
    },
    components: {
        CompanyLine,
        CreateCompanyForm,
        AddNewUsersForm,
        UsersListByCompany
    },
    methods: {
        ...mapActions({
            getCompanyList: 'getApiCompanyListByCompanyPage',
            addCompanyApi: 'getApiAddCompanyByComanyPage',
            changeCompanyApi: 'putApiCompanyByCompanyPage',
            getApiUserList: 'getApiUserListByCompanyPage',
            postApiAddNewUser: 'postApiAddNewUser',
            deleteApiUser: 'deleteApiUser',
            putApiUser: 'putApiUser'
        }),
        chooseCompany(paramCompany) {
            if (paramCompany.id) {
                if(this.flagCreateCompanyForm == true) {
                    this.clickCreateCompany()
                }
                this.getApiUserList(paramCompany.id)
                this.paramUserForm = paramCompany
                this.middleColumn = shallowRef(AddNewUsersForm)
                this.rightColumn = shallowRef(UsersListByCompany)
            } else {
                this.middleColumn = shallowRef()
                this.rightColumn = shallowRef()
            }
            this.choosedCompanyId = paramCompany.id
        },
        clickCreateCompany() {
            if (this.flagCreateCompanyForm == true) {
                this.middleColumn = shallowRef()
                this.flagCreateCompanyForm = false
            } else {
                this.chooseCompany(0)
                this.middleColumn = shallowRef(CreateCompanyForm)
                this.flagCreateCompanyForm = true
            }
        },
        async addCompany(name, shortName) {
            await this.addCompanyApi({
                name: name,
                showing_name: shortName
            })
            this.clickCreateCompany() 
        },
        changeCompany(newParam) {
            this.changeCompanyApi(newParam)
            this.paramUserForm.shortName = newParam.name
            this.paramUserForm.id = newParam.id
            this.paramUserForm.name = newParam.showing_name
        },
        addNewUser(userParam) {
            userParam.role = 'user'
            userParam.company_id = this.choosedCompanyId
            this.postApiAddNewUser(userParam)
        },
        changePasswordUser(param) {
            this.flagChangePassword = !this.flagChangePassword
            this.paramUserChangePassword = param
        },
        async deleteUser(param) {
            await this.deleteApiUser(param.id)
        },
        async requestChangePasswordUser(param) {
            let indexUser = null
            this.userList.find((el, index)=> {
                if (el.id == param.id) {
                    indexUser = index
                }
            })
            if (indexUser != null) {
                const options = {
                    id: param.id,
                    password: param.password,
                    username: this.userList[indexUser].username,
                    company_id: this.userList[indexUser].company_id
                }
                await this.putApiUser(options)
                this.flagChangePassword = !this.flagChangePassword
                console.log(options)
            }
        }
    },
    created() {
        this.getCompanyList()
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.main-dask-company-page{
    height: 93vh;
    padding: 32px 1vw;
    overflow-y: auto;
    display: flex;

    .companies-place {
        width: 40vw;
        padding: 0 10px;
        
        .control-place {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 25px;
            
            .filter-company {
                width: 72px;
                font-weight: 400;
                font-size: 14px;
                color: var(--gray-dark-color);
                // border: 0 solid var(--gray-dark-color);
                border-bottom: 1px solid var(--gray-dark-color);
            }
            .add-company {
                border: 1px solid var(--gray-dark-color);
                background: none;
                border-radius: 3px;
                padding: 5px 15px;
                width: 121px;
                height: 27px;
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 14px;
                color: var(--black-color);
                transition: all .3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    transition: all .3s ease;
                }

                &:hover {
                    background: #94949417;
                }

                &:active {
                    background: #9494943a;
                }
            }

            .cancel-add-company {
                background: var(--red-color);
                .sign-button {
                    rotate: 45deg;
                }
                &:hover {
                    background: #f97676a9;
                }

                &:active {
                    background: #f976764d;
                }
            }
            
        }

        .company-list-place {
            display: flex;
            flex-direction: column;
        }

    }
    .middle-column {
        width: 29vw;
        padding: 0 10px;
    }
    .right-column {
        width: 29vw;
        padding: 0 10px;
    }
}

</style>