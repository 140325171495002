<template>
    <div class="create-users-form">
        <h1 class="middle-high">{{nameUserForm}}</h1>
        <div class="change-passford-form" v-if="flagChangeForm">
            <h3 class="name-form plain-text">Изменить пароль:</h3>
            <input type="text" v-model="changingPassword" class="plain-text" placeholder="Пароль">
            <div style="display:flex; justify-content: space-between;">
                <button class="plain-text abnormal-button" @click="closeFormChangePass">Отменить</button>
                <button class="plain-text abnormal-button" @click="changePassword">Изменить</button>
            </div>
        </div>
        <h3 class="name-form plain-text">Добавить нового пользователя:</h3>
        <input type="text" v-model="loginValue" class="plain-text" placeholder="Логин">
        <input type="text" v-model="passwordValue" class="plain-text" placeholder="Пароль">
        <button class="plain-text" @click="clickAddNewUser">Добавить</button>
        <h3 class="name-form plain-text">Изменить параметры компании:</h3>
        <input type="text" v-model="nameCompany" class="plain-text" :placeholder="'Название компании ('+ nameUserForm + ')'">
        <input type="text" v-model="shortNameCompany" class="plain-text" :placeholder="'Короткое имя на Английскоми ('+ shortNameCompanyForm + ')'">
        <button class="plain-text" @click="clickChangeName">Изменить</button>
    </div>
</template>

<script>

export default {
    name: 'UsersForm',
    props: ['nameUserForm', 'shortNameCompanyForm', 'idCompany', 'flagChangeForm', 'idUserChangedPassword', 'paramUserChangePassword'],
    emits: ['changeCompany', 'addNewUser', 'closeFormChangePassword', 'changePassword'],
    computed: {
    },
    data() {
        return {
            shortNameCompany: '',
            nameCompany: '',
            loginValue: '',
            passwordValue: '',
            changingPassword: ''
        }
    },
    components: {
    },
    methods: {
        clickAddNewUser() {
            const options = {
                username: this.loginValue,
                password: this.passwordValue
            }
            this.$emit('addNewUser', options)
            this.loginValue = ''
            this.passwordValue = ''
        },
        clickChangeName() {
            let options = {
                id: this.idCompany
            }
            if (this.nameCompany == '' && this.shortNameCompany == '') {
                return;
            }
            if (this.nameCompany != '') {
                options.showing_name = this.nameCompany
            } else {
                options.showing_name = this.nameUserForm
            }
            if (this.shortNameCompany != '') {
                options.name = this.shortNameCompany
            } else {
                options.name = this.shortNameCompanyForm
            }
            this.shortNameCompany = ''
            this.nameCompany = ''
            this.$emit('changeCompany', options)
            
        },
        closeFormChangePass() {
            this.$emit('closeFormChangePassword')
        },
        changePassword() {
            let options = {}
            options.id = this.paramUserChangePassword.id
            options.password = this.changingPassword
            this.$emit('changePassword', options)
            this.changingPassword = ''
        }
    },
    created() {
        // this.shortNameCompany = this.shortNameCompany
        // this.nameCompany = this.nameUserForm
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">

.create-users-form {
    display: flex;
    flex-direction: column;
    padding: 0 25px;

    .change-passford-form {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all .3s ease;
        .abnormal-button {
            margin: 20px 0;
        }
    }
    .name-form {
        margin-top: 25px;
    }

    input {
        border: 1px solid var(--gray-dark-color);
        border-radius: 4px;
        padding: 3px 6px;
        margin-top: 20px;
    }
    button {
        border-radius: 8px;
        background: var(--background-blue);
        color: #fff;
        padding: 6px 50px;
        margin-top: 20px;
        margin-left: auto;
        transition: all .1s ease;

        &:hover {
            background: #3f51b5d0;
        }
        &:active {
            background: #3f51b55e;
        }
    }
}

</style>