<template>
    <div class="create-company-form">
        <h1 class="name-form middle-high">Добавить компанию:</h1>
        <input type="text" v-model="showNameCompany" class="plain-text" placeholder="Название компании">
        <input type="text" v-model="nameCompany" class="plain-text" placeholder="Короткое имя на Английском">
        <button class="plain-text" @click="clickAddCompany">Добавить</button>
    </div>
</template>

<script>

export default {
    name: 'CompanyForm',
    emits: ['createCompany'],
    computed: {
    },
    data() {
        return {
            nameCompany: '',
            showNameCompany: ''
        }
    },
    components: {
    },
    methods: {
        clickAddCompany() {
            this.$emit('createCompany', this.nameCompany, this.showNameCompany);
            this.nameCompany = '';
            this.showNameCompany = '';
        },
    },
    created() {
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.create-company-form {
    // position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 25px;

    input {
        border: 1px solid var(--gray-dark-color);
        border-radius: 4px;
        padding: 3px 6px;
        margin-top: 20px;
    }

    button {
        border-radius: 8px;
        background: var(--background-blue);
        color: #fff;
        padding: 6px 50px;
        margin-top: 20px;
        margin-left: auto;
        transition: all .1s ease;

        &:hover {
            background: #3f51b5d0;
        }
        &:active {
            background: #3f51b55e;
        }
    }
}

</style>