<template>
    <div>
        <HighBoard
            @logOut="logOutClick"
            @clickCompany="openPageCompany"
            @clickDashboard="openPageDashboard"
            :username="userOptions.username"
            :role="userOptions.role"
        />
        <Transition name="fade" mode="out-in">
            <component :is="activeComponent"></component>
        </Transition>
    </div>
</template>
<script>

import { shallowRef } from 'vue'

import { mapMutations, mapGetters, mapActions } from 'vuex'
import DashboardVue from './Simple_elements/DashboardPage.vue'
import CompanyVue from './Simple_elements/CompanyPage.vue'
// import SensorWidget from '@/components/Simple_elements/SensorWidget.vue'
// import WindowGraph from '@/components/Simple_elements/WindowGraph.vue'
// import WindowParam from '@/components/Simple_elements/WindowParam.vue'
import HighBoard from '@/components/Simple_elements/HighBoard.vue'
// import MyCollapse from '@/components/Simple_elements/MyCollapse.vue'

export default {
    name: 'WindowDashboard',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            // sensorList: 'getSensorList',
            // StatusDescriptions: 'getStatusDescriptions'
        })
    },
    data() {
        return {
            activeComponent: shallowRef(DashboardVue)
            // switcherGraph: false,
            // switcherParam: false,
            // paramWindow: {
            // },
            // graphWindow: {
            // },
            // flagStopTimeout: false,
            // compressorsList: {
            //     compressor: [
            //         {
            //             id: 1,
            //             name: 'test',
            //             created_date: '2024.11.22 18:36:21.8675'
            //         }
            //     ],
            //     param: [
            //         {
            //             temperature: 112.3,
            //             vibration: 332.1,
            //             total_time: 123
            //         }
            //     ]
            // }
        }
    },
    components: {
        DashboardVue,
        // SensorWidget,
        // WindowGraph,
        // WindowParam,
        CompanyVue,
        HighBoard,
        // MyCollapse
    },
    methods: {
        // ...mapMutations({
        //     setLogin: 'setLogin'
        // }),
        ...mapActions({
            postLogout: 'postLogout',
        //     getSensorLastData: 'getSensorLastData',
        //     getStatusDescriptions: 'getStatusDescriptions',
        }),
        logOutClick() {
            // this.setLogin(!this.userOptions.loginStatus)
            this.postLogout()
        },
        openPageCompany() {
            this.activeComponent = shallowRef(CompanyVue)
            
        },
        openPageDashboard() {
            this.activeComponent = shallowRef(DashboardVue)
            
        },
        // openGraphWindow(id, name) {
        //     this.switcherGraph = true
        //     this.graphWindow = {
        //         id: id,
        //         name: name
        //     }
        // },
        // closeGraphWindow() {
        //     this.switcherGraph = false
        //     this.graphWindow = {}
        // },
        // openParamWindow(id, name) {
        //     this.switcherParam = true
        //     for (const property in this.sensorList) {
        //         this.sensorList[property].sensors.find(el=>{
        //             if (el.id_sensor==id && el.name == name) {
        //                 this.paramWindow.id = el.id_sensor
        //                 this.paramWindow.mac = el.MAC
        //                 this.paramWindow.name = el.name
        //                 this.paramWindow.date = el.created_date
        //                 this.paramWindow.company = el.company_id
        //             }
        //         })
        //     }
        // },
        // closeParamWindow() {
        //     this.switcherParam = false
        //     this.paramWindow = {}
        // },
        // getDiscriptionsOfStatus(findArray, code) {
        //     console.log(findArray, code)
        //     if (findArray) {
        //         let description = 'Неопределенный статус'; 
        //         findArray.find(el => {
        //             if (el.code == code) {
        //                 description = el.description
        //             }
        //         })
        //         return description
        //     } else {
        //         return ''
        //     }
        // },
        // updateData() {
        //     this.getSensorLastData();
        //     if (!this.flagStopTimeout) {
        //         setTimeout(this.updateData, 10000)
        //     }
        // },
    },
    created() {
        // this.flagStopTimeout = false
        // // this.getCompressors();
        // this.getSensorLastData();
        // setTimeout(this.updateData, 10000)
        // // this.getStatusDescriptions()
    },
    beforeUnmount() {
        // this.flagStopTimeout = true
    }
}

</script>
<style noscoped lang="scss">
// .high-board{
//     width: 100vw;
//     background: #3F51B5;
//     height: 7vh;
//     box-shadow: 3px 13px 8px 0px rgba(34, 60, 80, 0.2);
//     display: flex;
//     align-items: center;
    
//     .logout{
//         cursor: pointer;
//         margin: 0px 1vw;
//         height: 50%;
//         background: #fff;
//         padding: 4px 10px;
//         border-radius: 4px;
//         i{
//             margin: 0px 6px;
//             color:#3f51b5;
//         }
//     }
// }

.main-dask{
    height: 93vh;//issue: should be 93vh
    // display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-content: flex-start;
    padding: 10px 1vw;
    overflow-y: auto;
}

</style>



