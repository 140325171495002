<template>
    
    <div class="background-window" @click.self="closeWindow">
        <div class="window-graph">
            <div class="head-window">
                <h2 class="name-window-param middle-high">{{name}}</h2>
                <button @click="closeWindow">
                    <img src="@/assets/imgs/crosser-black.svg" alt="">
                </button>
            </div>
            <div class="contral-line">
                <div class="form-part">
                    <div class="el-form" name-line="Период:">
                        <el-date-picker
                            v-model="date"
                            type="datetimerange"
                            start-placeholder="Начальная дата"
                            end-placeholder="Конечная дата"
                            size="small"
                            format="DD/MM/YYYY HH:mm"
                            popper-class="datepicker-custom"
                        />
                    </div>
                    <div class="el-form place-chackbox" name-line="Параметры:">
                        <el-select
                            v-model="checkboxObject"
                            multiple
                            collapse-tags
                            collapse-tags-tooltip
                            size="small"
                            placeholder="Выберите параметры"
                        >
                            <el-option
                                v-for="item in typeWindow=='sensor'?optionsSensorSelector:optionsCounterSelector"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                    <div class="el-form compara-form">
                        <el-checkbox v-model="compareFlag" label="Сравнить с другими" size="small" style="margin-right: 10px"/>
                        <el-select
                            v-model="checkboxObjectCompareId"
                            multiple
                            collapse-tags
                            :disabled="!compareFlag"
                            collapse-tags-tooltip
                            size="small"
                            placeholder="Выберите параметры"
                        >
                            <el-option
                                v-for="el in elementListShow" :key="typeWindow == 'sensor'?el.id_sensor:el.id_counter"
                                :label="el.name?el.name:el.address"
                                :value="typeWindow == 'sensor'?el.id_sensor:el.id_counter"
                            />
                        </el-select>
                    </div>
                    <div class="el-form" @mouseleave="hideDirectboard(true)" @mouseover="hideDirectboard(false)">
                        <img id="img-info" src="@/assets/imgs/info-circle.svg" alt="">
                        <div class="info-board" :class="{ 'showe-board': discriberBoard }">
                            <p class="plain-text">Справка по параметрам</p>
                        </div>
                    </div>
                </div>
                <button class="button-load-data" @click="getData">Посмотреть</button>
            </div>
            <highcharts class="highchart" :options="chartOptions" :highcharts="hcInstance" />
        </div>
        <!-- <button @click="test">asd</button> -->
    </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
// import Highcharts from 'highcharts'
import axios from '../../../config-axios'
import { mapActions, mapGetters } from 'vuex'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import { ElMessage } from 'element-plus'

exportingInit(Highcharts)
export default {
    name: "WindowGraph",
    props: ['id', 'name', 'listElements', 'typeWindow'],
    emits: ['closeWindow'],
    computed: {
        ...mapGetters({
            sensorList: 'getSensorList',
        }),
        elementListShow() {
            const listFiltered = this.listElements
            if (this.typeWindow == 'sensor') {
                return listFiltered.filter(el => el.id_sensor != this.id)
            } else if (this.typeWindow == 'counter') {
                return listFiltered.filter(el => el.id_counter != this.id)
            }
        }
    },
    data() {
        return {
            discriberBoard: false,
            compareFlag: false,
            compareSensor: [],
            optionsSensorSelector: [
                {
                    value: 'temperature',
                    label: 'Температура'
                },
                {
                    value: 'vibration',
                    label: 'Вибрация'
                },
                {
                    value: 'total_time',
                    label: 'Время работы'
                }
            ],
            optionsCounterSelector: [
                {
                    value: 'voltage',
                    label: 'Напржение'
                },
                {
                    value: 'frequency',
                    label: 'Частота'
                },
                {
                    value: 'cosFi',
                    label: 'cos φ'
                },
                {
                    value: 'current',
                    label: 'Ток'
                },
                {
                    value: 'energy',
                    label: 'Электроэнергия'
                }
            ],
            date: [],
            checkboxObject: [],
            checkboxObjectCompareId: [],
            hcInstance: Highcharts,
            typesLineForChart: [
                'Solid',
                'ShortDash',
                'ShortDot',
                'ShortDashDot',
                'ShortDashDotDot',
                'Dot',
                'Dash',
                'LongDash',
                'DashDot',
                'LongDashDot',
                'LongDashDotDot'
            ],
            currentUsingType: 0,
            chartOptions: {
                exporting: {
                    sourceWidth: 1920,
                    sourceHeight: 900,
                    scale: 1,
                },
                chart: {
                    zooming: {
                        type: 'x',
                        resetButton: {
                            position:{
                                x:-25,
                                y:5
                            }
                        }
                    },
                    backgroundColor: "#FCFCFC",
                },
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    shared: true,
                    crosshairs: true
                },
                series: [
                ],
                accessibility: {enabled: false},
                time: {
                    timezone: "Europe/Minsk",
                    timezoneOffset: 3,
                    useUTC:false
                },
                legend:{
                    // width: 350,
                    itemDistance: 5,
                    lineHeight: 14,
                    margin: 2,
                    maxHeight: 46
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        enabled: false
                    }
                },
            }
        }
    },
    methods: {
        test() {
        },
        fallbackHandler(options) {
            if (
                options.type !== 'image/svg+xml' && isOldEdgeBrowser ||
                options.type === 'application/pdf' && isMSBrowser
            ) {
                addText(options.type + ' fell back on purpose');
            } else {
                throw 'Should not have to fall back for this combination. ' +
                    options.type;
            }
        },
        hideDirectboard(status) {
            if (status) {
                this.discriberBoard = false
            } else {
                this.discriberBoard = true
            }
            
        },
        closeWindow() {
            this.$emit('closeWindow')
        },
        getData() {
            if (this.date.length == 2) {
                this.currentUsingType = 0;
                this.chartOptions.series = []
                this.chartOptions.series.forEach(el=> {
                    el.visible = false
                })
                let checkboxParam = {}
                if (this.typeWindow == 'sensor') {
                    checkboxParam.vibration = false;
                    checkboxParam.temperature = false;
                    checkboxParam.total_time = false;
                } else if (this.typeWindow == 'counter') {
                    checkboxParam.voltage = false;
                    checkboxParam.frequency = false;
                    checkboxParam.cos_fi = false;
                    checkboxParam.current = false;
                    checkboxParam.energy = false;
                }
                this.checkboxObject.forEach(el => {
                    if (el == 'vibration') checkboxParam.vibration = true;
                    if (el == 'temperature') checkboxParam.temperature = true;
                    if (el == 'total_time') checkboxParam.total_time = true;
                    if (el == 'voltage') checkboxParam.voltage = true;
                    if (el == 'frequency') checkboxParam.frequency = true;
                    if (el == 'cosFi') checkboxParam.cos_fi = true;
                    if (el == 'current') checkboxParam.current = true;
                    if (el == 'energy') checkboxParam.energy = true;
                })
                let options = {
                    start_time: this.date[0].toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }),
                    end_time: this.date[1].toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }),
                    param: checkboxParam,
                    type_element: this.typeWindow
                }
                if (!this.compareFlag) {
                    options.id = this.id
                    axios.post('sensor/graph/', options).then(response=>{
                        if (this.typeWindow == 'sensor') {
                            response.data.vibration.length?this.pushDataToGraph('Вибрация', 'мм/с', response.data.vibration):null
                            response.data.temperature.length?this.pushDataToGraph('Температура', '°C', response.data.temperature):null
                            response.data.total_time.length?this.pushDataToGraph('Время работы', 'ч', response.data.total_time):null
                        } else {
                            response.data.voltage.length?this.pushDataToGraph('Напряжение', 'В', response.data.voltage):null
                            response.data.frequency.length?this.pushDataToGraph('Частота', 'Гц', response.data.frequency):null
                            response.data.cos_fi.length?this.pushDataToGraph('cos φ', '', response.data.cos_fi):null
                            response.data.current.length?this.pushDataToGraph('Ток', 'А', response.data.current):null
                            response.data.energy.length?this.pushDataToGraph('Электроэнергия', 'Вт*ч', response.data.energy):null
                        }
                        if (this.chartOptions.series.length === 0) {
                            ElMessage('За данный период данных нет')
                        }
                    })
                } else {
                    options.id = [this.id, ...this.checkboxObjectCompareId]
                    axios.post('sensor/graph_compare/', options).then(response=>{
                        response.data.forEach(el=> {
                            if (this.typeWindow == 'sensor') {
                                el.vibration.length?this.pushDataToGraph('Вибрация('+el.name+')', 'мм/с', el.vibration, 1):null
                                el.temperature.length?this.pushDataToGraph('Температура('+el.name+')', '°C', el.temperature, 1):null
                                el.total_time.length?this.pushDataToGraph('Время работы('+el.name+')', 'ч', el.total_time, 60):null
                            } else {
                                el.voltage.length?this.pushDataToGraph('Напряжение('+el.name+')', 'В', el.voltage):null
                                el.frequency.length?this.pushDataToGraph('Частота('+el.name+')', 'Гц', el.frequency):null
                                el.cos_fi.length?this.pushDataToGraph('cos φ('+el.name+')', '', el.cos_fi):null
                                el.current.length?this.pushDataToGraph('Ток('+el.name+')', 'А', el.current):null
                                el.energy.length?this.pushDataToGraph('Электроэнергия('+el.name+')', 'Вт*ч', el.energy):null
                            }
                        })
                        if (this.chartOptions.series.length === 0) {
                            ElMessage('За данный период данных нет')
                        }
                    })
                }
            } else {
                ElMessage('Введите дату')
            }
        },
        pushDataToGraph(name, unit, dataArray, divider) {
            let indexChart = null
            this.chartOptions.series.find((el, index)=>{
                if (el.name == name) {
                    indexChart = index
                    return true
                }
            })
            if (indexChart == null) {
                const emptySeries = {
                    data: [],
                    name: name,
                    turboThreshold:10000,
                    visible: false,
                    dashStyle: this.typesLineForChart[this.currentUsingType],
                    tooltip: {
                        // pointFormat: '<b>'+name+':</b> {point.y} <i>'+ unit + '</i><br>',
                        pointFormat: name+': <b> {point.y} </b> <i>'+ unit + '</i><br>',
                    }
                }
                if (this.currentUsingType+1 == this.typesLineForChart.length) {
                    this.currentUsingType = 0;
                } else {
                    this.currentUsingType += 1;
                }
                indexChart = this.chartOptions.series.push(emptySeries) - 1;
            }
            var newArrayChart = dataArray.map(dote=>{
                const date1 = new Date(dote[0])
                const answer = [date1.valueOf(), dote[1]!=null?Number(dote[1].toFixed(2)):dote[1]]
                return answer
            })
            
            this.chartOptions.series[indexChart].data = newArrayChart
            if (newArrayChart.length > 0) {
                this.chartOptions.series[indexChart].visible = true
            }
        }
    },
    components: {
        highcharts: Chart 
    },
}
</script>
<style noscoped lang="scss">

.place-chackbox {
    width: 9.5vw;
}
.compara-form {
    width: 16.5vw;
}
.highchart{
    // height: 100%;
    flex-grow: 3;
}

.background-window{
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.295);
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}

.window-graph{
    background: var(--background-white, #FCFCFC);
    width: 98%;
    height: 90%;
    cursor: auto;
    border-radius: 4px;
    padding: 12px 9px;
    display: flex;
    flex-direction: column;

    
    .head-window {
        display: flex;
        justify-content: space-between;

        button {
            padding: 1px 2px;
            background: none;
            cursor: pointer;
        }
    }

    .contral-line {
        margin: 15px 25px;
        display: flex;
        justify-content: space-between;

        .form-part  {
            display: flex;

            // & * {
            //     margin-right: 10px;
            // }
            .el-form:not(:first-child) {
                margin-left: 31px;
                display: flex;
                justify-content: center;
                position: relative;
    
                #img-info {
                    height: 20px;
                    display: flex;
                    align-self: center;
                }

                .info-board {
                    padding: 5px 10px;
                    z-index: 3;
                    position: absolute;
                    top: 115%;
                    background: red;
                    width: 28vw;
                    min-width: 320px;
                    // height: 344px;
                    border-radius: 4px;
                    background: var(--background-white, #FCFCFC);
                    box-shadow: 0px 0px 28.1px 2px rgba(0, 0, 0, 0.25);
                    transition: all .2s ease;
                    opacity: 0;
                    display: none;
                }

                .showe-board {
                    opacity: 1;
                    display: inline;
                }
            }
        }

        .button-load-data {
            cursor: pointer;
            display: flex;
            width: 200px;
            height: 23px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--background-blue, #3F51B5);
            color: var(--background-white, #FCFCFC);
            transition: all .2s ease;
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            
        
            &:hover {
                box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
            }
            &:active {
                box-shadow: none;
            }
        }
    }
}



@media screen and (max-width: 600px) {
    .el-date-editor.el-input__wrapper {
        width: 100% !important;
    }
    .el-select--small {
        width: 87%;
    }
    // .el-input {
    //     width: 100% !important;
    // }
    // .button-load-data {
    //     margin: 5px 10px !important;
    // }
    .datepicker-custom {
        .el-input__inner{
            height: 18px;
        }
        .el-date-range-picker {
            width: 323px;
            .el-picker-panel__body {
                min-width: 323px;
            }
        }
        .el-picker-panel {
            line-height: 20px;
        }
        .el-picker-panel__content {
            float: none;
            width: 100%;
            padding: 2px;

            .el-date-range-picker__header {
                height: 23px;
            }
            .el-date-table th {
                padding:2px 5px;
            }
            .el-date-table td {
                padding: 0;
            }
        }
        .el-date-range-picker__time-header {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .window-graph {
        .place-chackbox {
            width: 88.5vw;
        }
        .head-window {
            margin-bottom: 10px;
        }
        .contral-line {
            flex-direction: column;
            margin: 5px 0px;

            .form-part  {
                flex-direction: column;
                position: relative;

                .el-form:not(:first-child) {
                    margin-left: 0;
                    justify-content: flex-start;
                    margin-top: 20px;
                }

                .el-form:last-child {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    .info-board {
                        right: 0px;
                    }
                }
                .el-form:not(:last-child) {
                    
                    position: relative;
                }
                .el-form:not(:last-child)::before {
                    content: attr(name-line);
                    position: absolute;
                    bottom: 100%;
                    font-family: Lato;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            
            .button-load-data {
                width: 100%;
                margin-top: 10px;
            }
            
        }
    }
}
</style>