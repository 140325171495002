<template>
    <div class="main-dask">
        <div v-if="Object.keys(sensorList).length != 1">
            <MyCollapse
                v-for="company, companyName in sensorList"
                :nameCollapse="company.show_name"
                :key="companyName"
            >
                <div class="widget-area">
                    <p class="rows-name middle-high">Датчики</p>
                    <div class="widget-row-place">
                        <SensorWidget
                            v-for="item in company.sensors" :key="item.id_sensor"
                            :name="item.name"
                            :mac="item.MAC"
                            :temperature="item.temperature"
                            :vibration="item.vibrations"
                            :total_time="item.total_time"
                            :month_time="item.month_time"
                            :week_time="item.week_time"
                            :day_time="item.day_time"
                            :rpm="item.rpm"
                            :power="item.power"
                            :id="item.id_sensor"
                            :addition_params="item.addition_params"
                            @open-graf="openGraphWindow"
                            @open-param="openParamWindow"
                        />
                    </div>
                </div>
                <div class="widget-area" v-if="company.counters.length != 0">
                    <p class="rows-name middle-high">Счетчики</p>
                    <div class="widget-row-place">
                        <CounterWidget
                            v-for="item in company.counters" :key="item.id_counter"
                            :name="item.name"
                            :address="item.address"
                            :voltage="item.voltage"
                            :frequency="item.frequency"
                            :cosFi="item.cos_fi"
                            :current="item.current"
                            :energy="item.energy_total"
                            :energyDay="item.energy_day"
                            :id="item.id_counter"
                            @open-graf="openGraphWindow"
                            @open-param="openParamWindow"
                        />
                    </div>
                </div>
                
            </MyCollapse>
        </div>
        <div class="solo-company" v-else v-for="company, companyName in sensorList" :key="companyName">
            <!-- <SensorWidget
                v-for="item in company.sensors" :key="item.id_sensor"
                :name="item.name"
                :mac="item.MAC"
                :temperature="item.temperature"
                :vibration="item.vibrations"
                :total_time="item.total_time"
                :month_time="item.month_time"
                :week_time="item.week_time"
                :day_time="item.day_time"
                :rpm="item.rpm"
                :power="item.power"
                :id="item.id_sensor"
                :addition_params="item.addition_params"
                @open-graf="openGraphWindow"
                @open-param="openParamWindow"
            /> -->
            <div class="widget-area">
                    <p class="rows-name middle-high">Датчики</p>
                    <div class="widget-row-place">
                        <SensorWidget
                            v-for="item in company.sensors" :key="item.id_sensor"
                            :name="item.name"
                            :mac="item.MAC"
                            :temperature="item.temperature"
                            :vibration="item.vibrations"
                            :total_time="item.total_time"
                            :month_time="item.month_time"
                            :week_time="item.week_time"
                            :day_time="item.day_time"
                            :rpm="item.rpm"
                            :power="item.power"
                            :id="item.id_sensor"
                            :addition_params="item.addition_params"
                            @open-graf="openGraphWindow"
                            @open-param="openParamWindow"
                        />
                    </div>
                </div>
                <div class="widget-area" v-if="company.counters.length != 0">
                    <p class="rows-name middle-high">Счетчики</p>
                    <div class="widget-row-place">
                        <CounterWidget
                            v-for="item in company.counters" :key="item.id_counter"
                            :name="item.name"
                            :address="item.address"
                            :voltage="item.voltage"
                            :frequency="item.frequency"
                            :cosFi="item.cos_fi"
                            :current="item.current"
                            :energy="item.energy_total"
                            :energyDay="item.energy_day"
                            :id="item.id_counter"
                            @open-graf="openGraphWindow"
                            @open-param="openParamWindow"
                        />
                    </div>
                </div>
        </div>
        <Transition name="fade" mode="out-in">
            <WindowGraph
                v-if="switcherGraph"
                @closeWindow="closeGraphWindow"
                :name="graphWindow.name"
                :id="graphWindow.id"
                :listElements="graphWindow.listElements"
                :typeWindow="graphWindow.typeWindow"
            />
        </Transition>
        <Transition name="fade" mode="out-in">
            <WindowParam
                v-if="switcherParam"
                @closeWindow="closeParamWindow"
                :id="paramWindow.id"
                :mac="paramWindow.mac"
                :name_element="paramWindow.name"
                :date="paramWindow.date"
                :company_id="paramWindow.company"
                :addition_params="paramWindow.addition_params"
                :typeWindow="paramWindow.typeWindow"
            />
        </Transition>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import SensorWidget from '@/components/Simple_elements/SensorWidget.vue'
import CounterWidget from '@/components/Simple_elements/CounterWidget.vue'
import WindowGraph from '@/components/Simple_elements/WindowGraph.vue'
import WindowParam from '@/components/Simple_elements/WindowParam.vue'
import HighBoard from '@/components/Simple_elements/HighBoard.vue'
import MyCollapse from '@/components/Simple_elements/MyCollapse.vue'

export default {
    name: 'Dashboard',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            sensorList: 'getSensorList',
            StatusDescriptions: 'getStatusDescriptions'
        })
    },
    data() {
        return {
            switcherGraph: false,
            switcherParam: false,
            paramWindow: {
            },
            graphWindow: {
            },
            flagStopTimeout: false,
            compressorsList: {
                compressor: [
                    {
                        id: 1,
                        name: 'test',
                        created_date: '2024.11.22 18:36:21.8675'
                    }
                ],
                param: [
                    {
                        temperature: 112.3,
                        vibration: 332.1,
                        total_time: 123
                    }
                ]
            }
        }
    },
    components: {
        SensorWidget,
        CounterWidget,
        WindowGraph,
        WindowParam,
        HighBoard,
        MyCollapse
    },
    methods: {
        ...mapMutations({
            setLogin: 'setLogin'
        }),
        ...mapActions({
            postLogout: 'postLogout',
            getSensorLastData: 'getSensorLastData',
            getStatusDescriptions: 'getStatusDescriptions',
        }),
        logOutClick() {
            // this.setLogin(!this.userOptions.loginStatus)
            this.postLogout()
        },
        openGraphWindow(id, name, typeWindow) {
            this.switcherGraph = true
            let listElemForGraph = [];
            for (const [key, value] of Object.entries(this.sensorList)) {
               if (typeWindow == 'sensor' && null != value.sensors.find(el => el.id_sensor == id)) {
                listElemForGraph = value.sensors;
                console.log(listElemForGraph)
               }
               if (typeWindow == 'counter' && null != value.counters.find(el => el.id_counter == id)) {
                listElemForGraph = value.counters;
                console.log(listElemForGraph)
               }
            }
            this.graphWindow = {
                id: id,
                name: name,
                typeWindow: typeWindow,
                listElements: listElemForGraph
            }
        },
        closeGraphWindow() {
            this.switcherGraph = false
            this.graphWindow = {}
        },
        openParamWindow(id, name, typeWindow) {
            // this.switcherParam = true
            if (typeWindow=='sensor') {
                for (const property in this.sensorList) {
                    this.sensorList[property].sensors.find(el=>{
                        if (el.id_sensor==id && el.name == name) {
                            this.paramWindow.typeWindow = typeWindow
                            this.paramWindow.id = el.id_sensor
                            this.paramWindow.mac = el.MAC
                            this.paramWindow.name = el.name
                            this.paramWindow.date = el.created_date
                            this.paramWindow.company = el.company_id?el.company_id:0
                            this.paramWindow.addition_params = el.addition_params
                        }
                    })
                }
                this.switcherParam = true
            } else if (typeWindow=='counter') {
                for (const property in this.sensorList) {
                    this.sensorList[property].counters.find(el=>{
                        if (el.id_counter==id && el.name == name) {
                            this.paramWindow.typeWindow = typeWindow
                            this.paramWindow.id = el.id_counter
                            this.paramWindow.mac = el.address
                            this.paramWindow.name = el.name
                            this.paramWindow.date = el.created_date
                            this.paramWindow.company = el.company_id?el.company_id:0
                            this.paramWindow.addition_params = []
                        }
                    })
                }
                this.switcherParam = true
            } else {
                alert("wrong property")
            }
            
        },
        closeParamWindow() {
            this.switcherParam = false
            this.paramWindow = {}
        },
        getDiscriptionsOfStatus(findArray, code) {
            console.log(findArray, code)
            if (findArray) {
                let description = 'Неопределенный статус'; 
                findArray.find(el => {
                    if (el.code == code) {
                        description = el.description
                    }
                })
                return description
            } else {
                return ''
            }
        },
        updateData() {
            this.getSensorLastData();
            if (!this.flagStopTimeout) {
                setTimeout(this.updateData, 10000)
            }
        },
    },
    created() {
        this.flagStopTimeout = false
        // this.getCompressors();
        this.getSensorLastData();
        setTimeout(this.updateData, 10000)
        // this.getStatusDescriptions()
    },
    beforeUnmount() {
        this.flagStopTimeout = true
    }
}
</script>

<style noscoped lang="scss">
.solo-company {
    overflow: hidden;
    transition: all .2s ease;
    padding: 24px 30px;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // gap: 10px;
}
.widget-row-place {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}
.rows-name {
    padding: 10px 20px;
    color: var(--gray-dark-color);
}
.widget-area {
    background: rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    margin: 5px 0;
}
// .high-board{
//     width: 100vw;
//     background: #3F51B5;
//     height: 7vh;
//     box-shadow: 3px 13px 8px 0px rgba(34, 60, 80, 0.2);
//     display: flex;
//     align-items: center;
    
//     .logout{
//         cursor: pointer;
//         margin: 0px 1vw;
//         height: 50%;
//         background: #fff;
//         padding: 4px 10px;
//         border-radius: 4px;
//         i{
//             margin: 0px 6px;
//             color:#3f51b5;
//         }
//     }
// }

.main-dask{
    height: 93vh;//issue: should be 93vh
    // display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-content: flex-start;
    padding: 10px 1vw;
    overflow-y: auto;
}
@media screen and (max-width: 1210px) {
    .widget-row-place {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 810px) {
    .widget-row-place {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>