<template>
    <div class="compressor-place" :class="{ high: addition_params.length == 2, low:  addition_params.length != 2}">
        <div class="first-line">
            <p class="middle-high" v-if="name">{{name}}</p>
            <p class="middle-high" v-if="!name">{{mac}}</p>
            <button @click="openWindowParam()">
                <img src="@/assets/imgs/pencil.svg" alt="">
            </button>
        </div>
        <div class="param-place">
            <div class="param-column">
                <div class="param-el" popup-text="Температура, °C">
                    <div class="icon-param">
                        <img src="@/assets/imgs/temperature.svg" alt="">
                    </div>
                    <p class="the-biggest">{{tempShow}}</p>
                    <p class="small-text name-param">Температура, °C</p>
                </div>
                <div class="param-el" popup-text="Вибрация, мм/с">
                    <div class="icon-param">
                        <img src="@/assets/imgs/vibration.svg" alt="">
                    </div>
                    <p class="the-biggest">{{vibrationShow}}</p>
                    <p class="small-text name-param">Вибрация, мм/с</p>
                </div>
                <div class="param-el" popup-text="Обороты, об/мин" v-if="addition_params.includes('rpm')">
                    <div class="icon-param">
                        <img src="@/assets/imgs/vibration.svg" alt="">
                    </div>
                    <p class="the-biggest">{{ rpm }}</p>
                    <p class="small-text name-param">Обороты, об/мин</p>
                </div>
                <div class="param-el" popup-text="Мощность, Вт" v-if="addition_params.includes('power')">
                    <div class="icon-param">
                        <img src="@/assets/imgs/vibration.svg" alt="">
                    </div>
                    <p class="the-biggest">{{ power }}</p>
                    <p class="small-text name-param">Мощность, Вт</p>
                </div>
                <div class="param-el" popup-text="Время работы(всё), ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-on.svg" alt="">
                    </div>
                    <p class="the-biggest">{{totalTimeShow}}</p>
                    <p class="small-text name-param">Время работы(всё), ч</p>
                </div>
            </div>
            <div class="param-column">
                <div class="param-el" popup-text="Время работы(месяц), ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-on.svg" alt="">
                    </div>
                    <p class="the-biggest">{{monthTimeShow}}</p>
                    <p class="small-text name-param">Время работы(месяц), ч</p>
                </div>
                <div class="param-el" popup-text="Время работы(неделя), ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-on.svg" alt="">
                    </div>
                    <p class="the-biggest">{{weekTimeShow}}</p>
                    <p class="small-text name-param">Время работы(неделя), ч</p>
                </div>
                <div class="param-el" popup-text="Время работы(день), ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-on.svg" alt="">
                    </div>
                    <p class="the-biggest">{{dayTimeShow}}</p>
                    <p class="small-text name-param">Время работы(день), ч</p>
                </div>
                <button class="white-status" @click="openGraphWindow()">
                    <img src="@/assets/imgs/icon-chart.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Compressor',
    props: ['name', 'temperature', 'vibration', 'total_time', 'month_time', 'week_time', 'day_time', 'power', 'rpm', 'id', 'mac', 'addition_params'],
    emits: ['open-graf', 'open-param'],
    methods: {
        openGraphWindow() {
            this.$emit('open-graf', this.id, this.name, 'sensor')
        },
        openWindowParam() {
            this.$emit('open-param', this.id, this.name, 'sensor')
        },
    },
    computed: {
        dayTimeShow() {
            if (this.day_time) {
                return (this.day_time/60).toFixed(1);
            } else {
                return '-'
            }
        },
        weekTimeShow() {
            if (this.week_time) {
                return (this.week_time/60).toFixed(1);
            } else {
                return '-'
            }
        },
        monthTimeShow() {
            if (this.month_time) {
                return (this.month_time/60).toFixed(1);
            } else {
                return '-'
            }
        },
        tempShow() {
            if (this.temperature) {
                let answer = (this.temperature).toFixed(1);
                return answer != '0.0' ? answer : '-';
            }
            return '-';
        },
        vibrationShow() {
            if (this.vibration) {
                let answer = (this.vibration).toFixed(2);
                return answer != '0.00' ? answer : '-';
            }
            return '-';
        },
        totalTimeShow() {
            if (this.total_time) {
                return (this.total_time/60).toFixed(1);
            } else {
                return '-'
            }
            // return this.total_time ? this.total_time : '-';
        },
    }
}
</script>
<style noscoped lang="scss">
    .compressor-place {
        // max-width: 573px;
        // min-width: 358px;
        height: 30.6vh;
        min-height: 97px;
        border-radius: 4px;
        background: var(--gray-color);
    }
    .compressor-place.low {
        // max-width: 573px;
        // min-width: 358px;
        height: 30.6vh;
    }
    
    .compressor-place.high {
        // max-width: 573px;
        // min-width: 358px;
        height: 36.6vh;
    }
    .first-line {
        display: flex;
        justify-content: space-between;
        padding: 3px 5px;

        button {
            cursor: pointer;
            background: none;
            padding: 2px 3px;
            border-radius: 3px;
            transition: all .2s ease;
            
            &:hover {
                background: #f8f6f6;
            }
            &:active {
                background: var(--background-white, #FCFCFC);
            }
        }
    }
    .param-place {
        display: flex;
        justify-content: space-between;
        padding: 0 19px;
        .param-column {
            width: 47%;
        }

        .color-param-to {
            background: rgba(149, 149, 253, 0.39);
        }
        .param-el {
            // width: 9vw;
            width: 100%;
            min-width: 117px;
            height: 5.5vh;
            min-height: 49px;
            border-radius: 5px;
            border: 2px solid var(--background-white, #FCFCFC);
            margin-bottom: 10px;
            position: relative;
            padding: 1px 11px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .icon-param {
                position: absolute;
                top: 4px;
                right: 4px;
                img {
                    height: 4vh;
                }
            }
            &:after {
                position: absolute;
                z-index: 1;
                white-space: nowrap;
                // height: 100%;
                top: 102%;
                background: var(--background-white);
                font-size: 14px;
                padding: 2px 5px;
                box-shadow: 0px 20px 40px 9px rgba(0, 0, 0, 0.26);
                display: flex;
                border-radius: 3px;
                flex-direction: column;
                justify-items: center;
                align-self: center;
                content: attr(popup-text);
                color: black;
                opacity: 0;
                transition: opacity .2s ease;
            }

            &:hover::after {
                // &:after{
                opacity: 1;
            }
        }
        button {
            width: 100%;
            min-width: 117px;
            height: 5.5vh;
            min-height: 49px;
            border-radius: 5px;
            border: 2px solid var(--background-white, #FCFCFC);
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s ease;

            &:hover {
                background: #f8f6f6;
            }
            &:active {
                background: var(--gray-color, #EDEDED);
            }
        }
        .white-status {
            background: var(--background-white, #FCFCFCFF);
        }
        .green-status {
            background: var(--green-color, #B3DDBE);
        }
        .red-status {
            background: var(--red-color, #F97676);
        }
    }
    @media screen and (max-width: 1700px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-column {
            width: 33.3%;
        }
        
    }
    @media screen and (max-width: 1210px) {
        .name-param {
            display: contents;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
    }
    @media screen and (max-width: 1150px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-column {
            width: 33.3%;
        }
    }
    @media screen and (max-width: 810px) {
        .name-param {
            display: contents;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
    }
    @media screen and (max-width: 610px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-place {
            padding: 0 1px;
        }
        .param-column {
            width: 33.3%;
        }
    }
    @media screen and (max-height: 887px) {
        .param-place {
            .param-el {
                margin-bottom: 2px;
            }
        }
        .first-line {
            p {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            img {
                height: 13px;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
</style>
