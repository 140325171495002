import { createStore } from 'vuex'
import { ElMessage } from 'element-plus'
import axios from '../../config-axios'

export default createStore({
  state: {
    userOptions: {
      loginStatus: false,
      username: null,
      role: 'guest'
    },
    windowLogin: {
      loaderButton: false
    },
    sensorList: [],
    statusDescriptions: {},
    companyList: [],
    companyListByCompanyPage: [],
    userListByCompanyPage: []
  },
  getters: {
    getUserOptions(state) {
      return state.userOptions
    },
    getLoaderLoginButton(state) {
      return state.windowLogin.loaderButton
    },
    getSensorList(state) {
      return state.sensorList
    },
    getStatusDescriptions(state) {
      return state.statusDescriptions
    },
    getCompanyList(state) {
      return state.companyList
    },
    getCompanyListByCompanyPage(state) {
      return state.companyListByCompanyPage
    },
    getUserListByCompanyPage(state) {
      return state.userListByCompanyPage
    }
  },
  mutations: {
    setLogin(state, options) {
      state.userOptions.loginStatus = options
    },
    setLoaderButtonLogin(state, options) {
      state.windowLogin.loaderButton = options
    },
    setUserParam(state, options) {
      state.userOptions.loginStatus = true
      state.userOptions.username = options.username
      state.userOptions.role = options.role
    },
    setSensorList(state, options) {
      state.sensorList = options
    },
    // setSensorParam(state, options) {
    //   let indexCom = null
    //   state.sensorList.find((el, index)=>{
    //     if (options.id == el.id_sensor) {
    //       indexCom = index
    //       return true
    //     }
    //   })
    //   state.sensorList[indexCom].name = options.name
    // },
    setCompanyList(state, options) {
      state.companyList = options
      state.companyList.unshift({ id: 0, label: 'Не зарегистрированные' })
    },
    setStatusDescriptions(state, options) {
      state.statusDescriptions = options
    },
    setCompanyListByCompanyPage(state, options) {
      state.companyListByCompanyPage = options
    },
    setAddCompanyByCompanyPage(state, options) {
      const oldCompanyList = state.companyListByCompanyPage
      state.companyListByCompanyPage = [ ...oldCompanyList, options]
    },
    setChangeCompanyByCompanyPage(state, options) {
      let indexCompany = null
      state.companyListByCompanyPage.find((el, index) => {
        if (el.id == options.id) {
          indexCompany = index
        }
      })
      if (indexCompany != null) {
        state.companyListByCompanyPage[indexCompany] = options
      }
    },
    setUserListByCompanyPage(state, options) {
      state.userListByCompanyPage = options
    },
    setAddNewUserByCompanyPage(state, options) {
      const oldList = state.userListByCompanyPage
      state.userListByCompanyPage = [...oldList, options]
      let indexComp = null
      state.companyListByCompanyPage.find((el, index) => {
        if (el.id == options.company_id) {
          indexComp = index
        }
      })
      console.log(indexComp)
      if (indexComp != null) {
        state.companyListByCompanyPage[indexComp].count_users = state.companyListByCompanyPage[indexComp].count_users + 1
      }
    },
    setDeleteUser(state, options) {
      let userIndex = null;
      let companyIndex = null;
      state.userListByCompanyPage.find((el, index)=> {
        if (el.id == options) {
          userIndex = index;
        }
      })
      if (userIndex != null) {
        state.companyListByCompanyPage.find((el, index) => {
          if (el.id == state.userListByCompanyPage[userIndex].company_id) {
            companyIndex = index;
          }
        })
        state.userListByCompanyPage.splice(userIndex, 1);
        if (companyIndex != null) {
          state.companyListByCompanyPage[companyIndex].count_users = state.companyListByCompanyPage[companyIndex].count_users - 1;
        }
      }
    }
  },
  actions: {
    async postLogin(context, data){
      context.commit('setLoaderButtonLogin', true)
      axios.defaults.headers.common = {}
      await axios.post('user/login/', data).then( response => {
        context.commit('setLogin', true)
        localStorage.setItem('token', response.data.token)
        axios.defaults.headers.common = {'Authorization': `Token ${response.data.token}`}
        context.commit('setLoaderButtonLogin', false)
        context.commit('setUserParam', response.data)
      }).catch( error => {
        console.log(error)
        ElMessage.error('Неверные параметры для входа')
        context.commit('setLoaderButtonLogin', false)
      })
    },
    async postLogout(context) {
      axios.defaults.headers.common = {}
      localStorage.setItem('token', '')
      context.commit('setLogin', false)
    },
    async checkUserInfo(context) {
      await axios.get('user/info/').then( (response) => {
        context.commit('setUserParam', response.data)
      }).catch( error => {
        console.log(error)
      })
    },
    async getSensorLastData(context) {
      await axios.get('sensor_point/').then( response => {
          context.commit('setSensorList', response.data)
      }).catch(error=>{
        console.log(error)
        // alert('Error request')
        ElMessage.error('Ошибка, не получилось получить данные о оборудовании')
      })
    },
    async putSensorSettings(context, options) {
      await axios.put('sensor/settings/', options).then(response=>{
        // context.commit('setSensorParam', response.data)
        context.dispatch('getSensorLastData')
        ElMessage.success('Параметры изменены')
      }).catch(error=>{
        console.log(error)
        // alert('Error request')
        ElMessage.error('Ошибка, не удалось изменить параметры')
      })
    },
    async getStatusDescriptions(context) {
      await axios.get('get_status_descriptions/').then(response => {
        context.commit('setStatusDescriptions', response.data)
      }).catch(error=>{
        console.log(error)
        ElMessage.error('Ошибка, определения статусов')
      })
    },
    async getApiCompanyList(context) {
      await axios.get('company/').then(response => {
        context.commit('setCompanyList', response.data)
      }).catch(error=>{
        console.log(error)
        ElMessage.error('Ошибка, не удалось получить список компаний')
      })
    },
    async getApiCompanyListByCompanyPage(context) {
      await axios.get('user/company/').then(response => {
        context.commit('setCompanyListByCompanyPage', response.data)
      }).catch(()=> {
        ElMessage.error('Ошибка, не удалось получить список компаний')
      })
    },
    async getApiAddCompanyByComanyPage(context, options) {
      await axios.post('user/company/', options).then(response => {
        context.commit('setAddCompanyByCompanyPage', response.data)
        ElMessage.success('Компания добавлена')
      }).catch(()=> {
        ElMessage.error('Ошибка, не удалось добавить компанию')
      })
    },
    async putApiCompanyByCompanyPage(context, options) {
      await axios.put('user/company/'+options.id+'/', options).then(response=>{
        context.commit('setChangeCompanyByCompanyPage', response.data)
        ElMessage.success('Компания изменина')
      }).catch(()=>{
        ElMessage.error('Ошибка, не удалось изменить компанию')
      })
    },
    async getApiUserListByCompanyPage(context, options) {
      await axios.get('user/company/'+options+'/user/').then(response=>{
        context.commit('setUserListByCompanyPage', response.data)
      }).catch(()=>{
        ElMessage.error('Ошибка, не удалось загрузить список пользователей')
      })
    },
    async postApiAddNewUser(context, options) {
      await axios.post('user/company/user/', options).then(response=>{
        context.commit('setAddNewUserByCompanyPage', response.data)
        ElMessage.success('Пользователь добавлен')
      }).catch(()=>{
        ElMessage.error('Ошибка, не удалось добавить пользователя')
      })
    },
    async deleteApiUser(context, options) {
      await axios.delete('user/company/user/'+options+'/').then(response=>{
        if (response.data.status) {
          context.commit('setDeleteUser', options)
          ElMessage.success('Пользователь удалён')
        } else {
          ElMessage.error('Ошибка, не удалось добавить пользователя')
        }
      })
    },
    async putApiUser(context, options) {
      await axios.put('user/company/user/'+options.id+'/', options).then(()=>{
        ElMessage.success('Пароль изменен')
      }).catch(()=>{
        ElMessage.error('Ошибка, не удалось изменить пароль')
      })
      
    } 
  },
  modules: {
  }
})
